const FooterData = {
  CompanyWidget: [
    {
      id: 1,
      title: "Permanece en contacto",
      description: "¿Te gustaría suscribirte?",
    },
  ],
  AboutWidget: [
    {
      id: 1,
      title: "Dirección",
      menuItems: [
        {
          id: 1,
          /*url: "#",*/
          text:
            "Edificio Milla de Oro, Avenida El Poblado, Carrera 42 Nº 3 Sur 81 Torre 1 Piso 15, Medellín, Antioquia",
        },
      ],
    },
    {
      id: 2,
      title: "Contacto",
      menuItems: [
        {
          id: 1,
          url: "#",
          text: "Email: info@epikos.co",
        },
        {
          id: 2,
          url: "#",
          text: "Teléfono:+57 300-2071543",
        },
        {
          id: 3,
          url: "#",
          text: "Teléfono:+57 318-7758015",
        },
        {
          id: 4,
          url: "#",
          text: "Teléfono:+57 (4) 4803139",
        },
      ],
    },
  ],

  SocialLinks: [
    {
      id: 1,
      title: "Encuentranos en",
      menuItems: [
        {
          id: 1,
          url: "https://www.facebook.com/epikos.co/",
          icon: "ti-facebook",
        },
        {
          id: 2,
          url: "https://www.linkedin.com/company/epikos-solutions",
          icon: "ti-linkedin",
        },
        {
          id: 3,
          url: "https://www.instagram.com/epikosco",
          icon: "ti-instagram",
        },
        /*{
          id: 4,
          url: "#",
          icon: "ti-youtube",
        },*/
      ],
    },
  ],

  copywrite: "© Epikos Solutions S.A.S 2020.",
};
export default FooterData;
