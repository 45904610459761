import React from "react";

import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import ServiceDetails from "../components/Service/ServiceDetails";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";

const Faq = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="resources/banner_bg.png"
        Ptitle="Política de tratamiento de datos personales"
        Pdescription="Epikos Solutions S.A.S."
      />
      <ServiceDetails />
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default Faq;
