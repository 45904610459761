import React from "react";
import SeoTitle from "../Title/SeoTitle";
import Fade from "react-reveal/Fade";

const Service = () => {
  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad">
        <div className="container">
          <SeoTitle
            Title="¿Qué hacemos?"
            TitleP="Buscar el desarrollo de nuevas empresas y hacer más eficaces las tradicionales, mediante el uso generalizado de las tecnologías de la información y la comunicación"
          />
          <div className="row seo_service_info">
            <Fade bottom duration={500}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/new/icon2.png")} alt="" />
                  <a href=".#">
                    <h4>SaaS</h4>
                  </a>
                  <p>
                    <b>Software como servicio</b> <br></br>
                    <br></br>
                    Aplicaciones en la nube, que permiten a los usuarios acceder
                    desde cualquier lugar.<br></br>
                    <br></br>
                    Aplicaciones que transforman la manera en la que se usa la
                    información y en la que se hacen negocios.
                  </p>
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={700}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/new/icon.png")} alt="" />
                  <a href=".#">
                    <h4>Ecommerce</h4>
                  </a>
                  <p>
                    <b>Comercio electrónico</b>
                    <br></br>
                    <br></br>
                    Transformación de modelos de comercialización y gestión de
                    relación con los clientes.<br></br> <br></br>Canales de
                    conexión y comunicación con el cliente para fortalecer las
                    relaciones a través del consumo.
                  </p>
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/new/leaf2.png")} alt="" />
                  <a href=".#">
                    <h4>Transformación digital</h4>
                  </a>
                  <p>
                    <b>Personas, herramientas, procesos.</b>
                    <br></br>
                    <br></br>
                    Optimización de procesos para crear productos y servicios,
                    que permitan adquirir competitividad local y global,
                    mejorando la productividad y conectando las personas con
                    personas, organizaciones y clientes.
                  </p>
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </Fade>
            <div className="col-lg-12 text-center mt_40">
              {/*<a href=".#" className="seo_btn seo_btn_one btn_hover">
                All Features
              </a>*/}
            </div>
          </div>
        </div>
      </section>
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="seo_features_img">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img src={require("../../img/seo/features_img.png")} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2>Nuestra Misión y Visión</h2>
                  <p>
                    Realmente vivimos por poder cumplir nuestra "Visión",
                    nuestra "Misión", nuestros sueños y queremos ayudarte a
                    cumplir los tuyos.
                  </p>
                  <div className="media seo_features_item">
                    <div className="icon two">
                      <img src={require("../../img/seo/icon4.png")} alt="" />
                    </div>
                    <div className="media-body">
                      <h3>Visión</h3>
                      <p>
                        Ser un canal de transformación digital que apoya a las
                        organizaciones a alcanzar su máximo potencial mediante
                        personas, herramientas y procesos.
                      </p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon">
                      <img src={require("../../img/seo/icon3.png")} alt="" />
                    </div>
                    <div className="media-body">
                      <h3>Misión</h3>
                      <p>
                        Ayudar a las empresas y PYMES en su proceso de inserción
                        en la economía digital, mediante soluciones y
                        herramientas que les permitan desarrollar competencias
                        digitales y ventajas competitivas.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      {/*  
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="seo_features_img seo_features_img_two">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img
                  src={require("../../img/seo/features_img_two.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2 className="wow fadeInUp">
                    Get tips & tricks on how to skyrocket your sales.
                  </h2>
                  <h6 className="wow fadeInUp">
                    What a plonker bamboozled so I said say what a load of
                    rubbish owt to do with me haggle.
                  </h6>
                  <p className="wow fadeInUp">
                    Cheeky bugger gosh codswallop chap bamboozled blatant
                    cracking goal brown bread, pear shaped cor blimey guvnor
                    easy peasy lemon squeezy hotpot spiffing good time, chancer
                    a spend a penny spiffing I don't want no agro tinkety tonk
                    old fruit.
                  </p>
                  <a
                    href=".#"
                    className="seo_btn seo_btn_one btn_hover wow fadeInUp"
                  >
                    Learn More
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    */}
    </React.Fragment>
  );
};

export default Service;
