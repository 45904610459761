import React from "react";

const ServiceDetails = () => {
  return (
    <section className="service_details_area sec_pad">
      <div className="container">
        <div className="row">

          <div className="col-lg-12">
            <div className="details_content">
              <div className="sec_title">
                <div className="f_400 f_size_15">


                  <h5>1. ALCANCE E IDENTIFICACION DEL RESPONSABLE DEL TRATAMIENTO DE DATOS</h5>

                  Esta política de protección de datos personales se aplicará a todas las bases de datos y/o Archivos que contengan datos personales que tengan o hayan adquirido alguna relación con la compañía:
                  <br></br><br></br>

                  <b>Nombre empresa:</b> Epikos Solutions S.A.S<br></br>

                  <b>Nit:</b> 900.423.873 - 3<br></br>

                  <b>Dirección:</b> Carrera 42 # 3 sur – 81 Torre 1 piso 15 Medellín, Colombia.<br></br>

                  <b>Correo electrónico de notificación:</b> info@epikos.co<br></br>

                  <b>Teléfono:</b> (+574) 4803139<br></br><br></br>


                  <h5>2. MARCO LEGAL</h5>

                  La presente política de Tratamiento de datos es elaborada de conformidad con lo dispuesto en la Constitución Política, la Ley 1581 de 2012, sus Decretos Reglamentarios y demás normas que las modifiquen, deroguen o sustituyan, siendo aplicada por Epikos Solutions S.A.S respecto de la recolección, almacenamiento, uso, circulación, supresión y de todas aquellas actividades que constituyan tratamiento de datos personales.
                  <br></br><br></br>



                  <h5>3. DEFINICIONES</h5>

                  a) Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos personales.
                  <br></br>
                  b) Aviso de privacidad: Documento físico, electrónico o en cualquier otro formato generado por el Responsable que se pone a disposición del Titular para el tratamiento de sus datos personales. En el Aviso de Privacidad se comunica al Titular la información relativa a la existencia de las políticas de tratamiento de información que le serán aplicables, la forma de acceder a las mismas y la finalidad del tratamiento que se pretende dar a los datos personales.
                  <br></br>
                  c) Base de Datos: Conjunto organizado de datos personales que sea objeto de Tratamiento.
                  <br></br>
                  d) Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.
                  <br></br>
                  e) Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de datos personales por cuenta del Responsable del Tratamiento.
                  <br></br>
                  f) Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos.
                  <br></br>
                  g) Titular: Persona natural cuyos datos personales sean objeto de Tratamiento.
                  <br></br>
                  h) Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión de los mismos.
                  <br></br><br></br>

                  <h5>4. FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES</h5>

                  De conformidad con lo dispuesto en la Ley 1581 de 2012 y el Decreto 1074 de 2015, Epikos Solutions S.A.S trata los datos personales de sus clientes, proveedores, empleados, aspirantes y accionistas para dar cumplimiento a las finalidades que se mencionan a continuación.
                  <br></br><br></br>
                  <h6>4.1. Finalidades datos personales de Clientes:</h6>  Evaluación y ofrecimiento de productos y servicios; informar cambios y/o nuevas versiones de los mismos, realizar encuestas acerca de la experiencia de usuario y calidad en el servicio,  suministrar información comercial, publicitaria o promocional a través de múltiples canales (correo electrónico, SMS, MMS, redes sociales entre otros) con el fin de impulsar, invitar, informar, dirigir y ejecutar procesos que contribuyan en el funcionamiento óptimo y evolución de la organización; celebración y ejecución de contratos; gestión de trámites (solicitudes, inconformidades, oportunidades de mejora, sugerencias, felicitaciones); suministrar información de contacto a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y cualquier tercero con el cual Epikos Solutions S.A.S tenga un vínculo contractual para el desarrollo de actividades de ese tipo (investigación de mercados y telemercadeo, etc) para la ejecución de las mismas, suministro de la información a terceros en cumplimiento de leyes, regulaciones, procesos legales y administrativos, o para detener o prevenir fraudes y/o ataques a la seguridad de la empresa.

                  <br></br><br></br>
                  <b>Uso de cookies</b>

                    Los sitios web de nuestros productos usan "cookies" para ayudar a personalizar la experiencia. Una cookie es un archivo de texto que se coloca en su disco duro por una página web servidor. Las cookies no se pueden usar para ejecutar programas o enviar virus a una computadora. Las cookies se asignan de manera exclusiva para cada cliente, y solo puede leerlas un servidor web en el dominio que emitió la cookie.

                  <br></br><br></br>

                  Uno de los propósitos principales de las cookies es proporcionar una característica de conveniencia para guardar el tiempo. El propósito de una cookie es decirle al servidor web que un usuario ha regresado a una página específica. Por ejemplo, si se personaliza algún producto o se realiza el registro en el sitioweb, una cookie ayuda a Epikos Solutions S.A.S a recordar la información específica en visitas posteriores. Esto simplifica el proceso de registrar información personal, como direcciones de facturación, direcciones de envío, y así. Cuando se retorne al mismo sitio web, la información proporcionada anteriormente se puede recuperar, por lo que se pueden usar fácilmente.
                  <br></br><br></br>
                  Cada titular tiene la posibilidad de aceptar o rechazar las cookies. La mayoría de los navegadores web automáticamente acepta cookies, pero generalmente se puede modificar la configuración de los navegadores para rechazar las cookies de acuerdo a la preferencia. En caso de rechazar las cookies, es posible que la experiencia de usuario no sea completa de acuerdo a las características interactivas de los servicios de Epikos Solutions S.A.S o sitios web que visitaste.
                  <br></br><br></br>

                  <h6>4.2. Finalidades datos personales de Proveedores:</h6> Cumplimiento de obligaciones contables y tributarias; reportes de ley, cumplimiento de obligaciones derivadas de las relaciones comerciales; y contacto para la adquisición de productos y servicios; suministro de la información a terceros en cumplimiento de leyes, regulaciones, procesos legales y administrativos, o para detener o prevenir fraudes y/o ataques a la seguridad de la empresa.
                  <br></br><br></br>
                  <h6>4.3. Finalidades datos personales de Empleados y Aspirantes:</h6> Cumplimiento de obligaciones de ley con relación al pago de salarios, prestaciones sociales y demás retribuciones consagradas en el contrato de trabajo o según disponga la ley; ofrecer programas de boenestar corporativo y planificar actividades empresariales para el titular y/o sus beneficiarios, tambien para la evaluación de competencias, habilidades y experiencia;  Suministro de información comercial, publicitaria, promocional, estratégica de productos y servicios de la compañía por múltiples canales; para fines contractuales y gestión derivada de la relación entre las partes.
                <br></br><br></br>
                  <h6>4.4. Finalidades datos personales de Accionistas:</h6> Control de accionistas de la empresa; cumplimiento de obligaciones contables y tributarias; y reportes de ley.
                <br></br><br></br>
                  <h5>5. TRATAMIENTO DE LOS DATOS PERSONALES</h5>

                  Actuando en calidad de Responsable del Tratamiento de Datos Personales y para el adecuado desarrollo de sus actividades comerciales, Epikos Solutions SAS recolecta, almacena, usa, circula y suprime los Datos Personales correspondientes a personas naturales con quienes tiene o ha tenido relación.
                  <br></br><br></br>
                  Cada vez que un titular realiza la vinculación a alguno de nuestros productos, autoriza el tratamiento de datos de acuerdo a nuestra política aquí descrita.
                  <br></br><br></br>
                  Epikos Solutions S.A.S informa que los datos personales podrán ser objeto de transmisión nacional o internacional a encargados de la información en desarrollo del objeto social para los fines autorizados; dicha información podrá ser transmitida a servidores ubicados en los Estados Unidos de Norteamérica, Canadá, países latinoamericanos y europeos o cualquier otro país para los fines del tratamiento autorizado o para propósitos de almacenamiento y/o servicios de hosting que Epikos Solutions S.A.S contrate o requiera.
                  <br></br><br></br>
                  <h5>6. DERECHOS DE LOS TITULARES DE DATOS PERSONALES OBJETO DE TRATAMIENTO</h5>

                  Los titulares de datos personales por sí o por intermedio de su representante y/o apoderado podrán ejercer los siguientes derechos:
                  <br></br><br></br>
                  a) Acceder de forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento.
                  <br></br>
                  b) Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado.
                  <br></br>
                  c) Solicitar prueba de la autorización otorgada.
                  <br></br>
                  d) Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente.
                  <br></br>
                  e) Revocar la autorización y/o solicitar la supresión del dato, siempre que no exista un deber legal o contractual que impida eliminarlos.
                  <br></br>
                  f) Abstenerse de responder las preguntas sobre datos sensibles. Tendrá carácter facultativo las respuestas que versen sobre datos sensibles o sobre datos de las niñas y niños y adolescentes.
                  <br></br><br></br>
                  <h5>7. ÁREA RESPONSABLE DE LA ATENCIÓN DE TRÁMITES (solicitudes, inconformidades, oportunidades de mejora, sugerencias, felicitaciones)</h5>

                  Epikos Solutions S.A.S ha dispuesto un canal de atención para que cualquier titular pueda ejercer sus derechos a conocer, actualizar, rectificar y suprimir el dato y/o revocar la autorización.
                  <br></br>
                  Los datos de contacto son los siguientes:
                  <br></br>
                  – Correo electrónico de contacto: info@epikos.co
                  <br></br>
                  – Teléfono: (4) 4803139
                  <br></br><br></br>

                  <h5>8. PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA</h5>

                  En cumplimiento de las normas sobre protección de datos personales, Epikos Solutions S.A.S presenta el procedimiento y requisitos mínimos para el ejercicio de sus derechos:
                  <br></br><br></br>


                  Para la radicación y atención de su solicitud le solicitamos suministrar la siguiente información:
                  <br></br><br></br>
                  *  Nombre completo y apellidos
                  <br></br>
                  *  Número de identificación
                  <br></br>
                  *  Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto)
                  <br></br>
                  *  Medios para recibir respuesta a su solicitud
                  <br></br>
                  *  Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información)
                  <br></br>
                  *  Firma (si aplica)
                  <br></br><br></br>

                  El término máximo previsto por la ley para resolver su reclamación es de quince (15) días hábiles, contado a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, Epikos Solutions S.A.S informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. Una vez cumplidos los términos señalados por la Ley 1581 de 2012 y las demás normas que la reglamenten o complementen, el Titular al que se deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, supresión y revocación, podrá poner su caso en conocimiento de la Superintendencia de Industria y Comercio –Delegatura para la Protección de Datos Personales-.
                  <br></br><br></br>


                  <h5>9. LIMITACIONES TEMPORALES AL TRATAMIENTO DE LOS DATOS PERSONALES</h5>

                  Epikos Solutions S.A.S podrá recolectar, almacenar, usar o circular los datos personales mientras no se solicite su suspención por el interesado y siempre que no exista un deber legal de conservarlos, de acuerdo con las finalidades que justificaron el tratamiento, atendiendo a las disposiciones aplicables a la materia de que se trate y a los aspectos administrativos, contables, fiscales, jurídicos e históricos de la información. Una vez cumplida(s) la(s) finalidad(es) del tratamiento y sin perjuicio de normas legales que dispongan lo contrario, procederá a la supresión solicitada de los datos personales en su posesión.

                  <br></br><br></br>
                  <h5>10. MEDIDAS DE SEGURIDAD</h5>

                  En desarrollo del principio de seguridad establecido en la Ley 1581 de 2012, la Empresa adoptará las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. El personal que realice el tratamiento de los datos personales ejecutará los protocolos establecidos con el fin de garantizar la seguridad de la información.

                  <br></br><br></br>
                  <h5>11. ENTRADA EN VIGENCIA</h5>

                  La presente Política de Datos Personales entra en vigencia a partir del día 01 de Julio de 2020. Cualquier cambio que se presente respecto de la presente política, se informará a través de la página web principal <a href="http://www.epikos.co/">www.epikos.co</a> o dirección electrónica <a href="mailto:info@epikos.co">info@epikos.co</a>.

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};
export default ServiceDetails;
